import { useState } from 'react';
import * as Yup from 'yup';
// form
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import { Link, Stack, Alert, IconButton, InputAdornment, TextField, InputLabel } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// auth
import { useAuthContext } from '../../auth/useAuthContext';
// components
import Iconify from '../../components/iconify';
import FormProvider, { RHFTextField } from '../../components/hook-form';
import { supabase } from '../../components/supabaseClient';

// ----------------------------------------------------------------------

export default function AuthLoginForm() {
  const { login } = useAuthContext();

  const [loading, setLoading] = useState(false)
  const [email, setEmail] = useState('')

  const handleLogin = async (e) => {
    e.preventDefault()
    try {
      setLoading(true)
      const { error } = await supabase.auth.signInWithOtp({ email })
      if (error) throw error
      alert('Check your email for the login link!')
    } catch (error) {
      alert(error.error_description || error.message)
    } finally {
      setLoading(false)
    }
  }

  const [showPassword, setShowPassword] = useState(false)

  const LoginSchema = Yup.object().shape({
    email: Yup.string().email('Email must be a valid email address').required('Email is required'),
    password: Yup.string().required('Password is required'),
  });

  const defaultValues = {
    email: 'demo@minimals.cc',
    password: 'demo1234',
  };

  const methods = useForm({
    resolver: yupResolver(LoginSchema),
    defaultValues,
  });

  const {
    reset,
    setError,
    handleSubmit,
    formState: { errors, isSubmitting, isSubmitSuccessful },
  } = methods;

  const onSubmit = async (data) => {
    try {
      await login(data.email, data.password);
    } catch (error) {
      console.error(error);

      reset();

      setError('afterSubmit', {
        ...error,
        message: error.message,
      });
    }
  };

  return (

    <div className="row flex-center flex">
      <div className="col-6 form-widget" aria-live="polite">
        <p className="description">
          Get access to our full database (not just 30 days). <br/>
          Sign in via magic link with your email below.  
        <br/>
        </p>
        <br/>
        {loading ? (
          'Sending magic link...'
        ) : (

          <label className="email" htmlFor="email">
            <InputLabel id ="email">
            <TextField
              label="Email"
              id="email"
              margin="dense"
              type="email"
              fullWidth
              loading={isSubmitSuccessful || isSubmitting}
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            </InputLabel>
            <form onSubmit={handleLogin}>
              <br/>

              <LoadingButton 
                type="submit" 
                id="email" 
                className="button block" 
                aria-live="polite"
                size="large"
                variant="contained"
                >
                Send magic link
              </LoadingButton>
            </form>
          </label>
        )}
      </div>
    </div >

    //   fullWidth
    //   color="inherit"
    //   size="large"
    //   type="submit"
    //   variant="contained"
    //   loading={isSubmitSuccessful || isSubmitting}
    //   sx={{
    //     bgcolor: 'text.primary',
    //     color: (theme) => (theme.palette.mode === 'light' ? 'common.white' : 'grey.800'),
    //     '&:hover': {
    //       bgcolor: 'text.primary',
    //       color: (theme) => (theme.palette.mode === 'light' ? 'common.white' : 'grey.800'),
    //     },
    //   }}
    // >
    //   Login
  );
}
