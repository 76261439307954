import * as React from 'react';
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import PropTypes from 'prop-types';
// @mui
import { DataGrid, GridToolbar, GridRenderCellParams, GridValueFormatterParams } from '@mui/x-data-grid';
import { IconButton, Typography, GridCellParams, MuiEvent, Chip } from '@mui/material';
// components 
import { useSettingsContext } from '../../../components/settings/SettingsContext';
import { fDate } from '../../../utils/formatTime';

const DataGridCoPeople = (props) => {

  const { themeStretch } = useSettingsContext();


  const columns = [

    {
      field: 'name',
      headerName: 'Name',
      flex: 1,
      valueGetter: (params) => { return params.row.name; },
    },
    {
      field: 'role',
      headerName: 'Role',
      flex: 1
    },
    {
      field: 'linkedin',
      headerName: 'Linkedin',
      flex: 1,
      renderCell: (params: GridRenderCellParams<String>) => (
        <a href={params.row.linkedin}
          target="_blank"
          rel="noreferrer"
          alt="logo"
        >{params.row.linkedin}</a>
      ),
    },
  ]

  return (
    <DataGrid
      columns={columns}
      // initialState={{
      //   sorting: {
      //     sortModel: [{ field: 'timestamp', sort: 'desc' }],
      //   },
      // }}
      rows={props.scanData}
      components={{ Toolbar: GridToolbar }}
      density="compact"
      componentsProps={{
        toolbar: {
          showQuickFilter: true,
          quickFilterProps: { debounceMs: 500 },
        },
      }}
    />
  );

}

export default DataGridCoPeople

// "name": (x.name),
// "id": x.id,
// "linkedin": (x.linkedin),