import { Navigate, useRoutes } from 'react-router-dom';
// auth
import AuthGuard from '../auth/AuthGuard';
import GuestGuard from '../auth/GuestGuard';
// layouts
import CompactLayout from '../layouts/compact';
import DashboardLayout from '../layouts/dashboard';
// config
import { PATH_AFTER_LOGIN } from '../config';
//
import { 
  Page404, 
  PageOne, 
  AboutPage, 
  PageSix, 
  PageFour, 
  PageFive, 
  LoginPage, 
  PageThree,
  HomePage, 
  TermsPage,
  PrivacyPage,
} from './elements';
import CompanyProfilePage from '../pages/CompanyProfilePage';
import Company from '../sections/companies/Company';
import Tracker from '../sections/trackers/Tracker';

// ----------------------------------------------------------------------

export default function Router() {
  return useRoutes([
    {
      path: '/',
      children: [
        { element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true },
        {
          path: '/',
          element: (
            <GuestGuard>
              <DashboardLayout />
            </GuestGuard>
          ),
        },
      ],
    },
    {
      path: '/',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true },
        { path: 'home', element: <HomePage /> },
        { path: 'about', element: <AboutPage /> },
        { path: 'three', element: <PageThree /> },
        { path: 'terms-and-conditions', element: <TermsPage /> },
        { path: 'privacy-policy', element: <PrivacyPage /> },
        { path: 'login', element: <LoginPage /> },
        { path: 'company/:companyId', element: <Company />,},
        { path: 'tracker/:tagName', element: <Tracker />,},
        {
          path: 'user',
          children: [
            { element: <Navigate to="/dashboard/user/four" replace />, index: true },
            { path: 'four', element: <PageFour /> },
            { path: 'five', element: <PageFive /> },
          ],
        },
      ],
    },
    {
      element: <CompactLayout />,
      children: [{ path: '404', element: <Page404 /> }],
    },
    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}
