import PropTypes from 'prop-types';
// @mui
import { Card, Stack, Typography, Divider } from '@mui/material';
// utils
import { fNumber } from '../../utils/formatNumber';

// ----------------------------------------------------------------------

EmployeesJobsHighlight.propTypes = {
  // employees: PropTypes.array,
  // jobOpenings: PropTypes.array,
};

export default function EmployeesJobsHighlight({ employees, jobOpenings }) {
  return (
    <Card sx={{ py: 3 }}>
      <Stack direction="row" divider={<Divider orientation="vertical" flexItem />}>
        <Stack width={1} textAlign="center">
          <Typography variant="h4">{fNumber(employees)}</Typography>

          <Typography variant="body2" sx={{ color: 'text.secondary' }}>
            Employees
          </Typography>
        </Stack>

        <Stack width={1} textAlign="center">
          <Typography variant="h4">{fNumber(jobOpenings)}</Typography>

          <Typography variant="body2" sx={{ color: 'text.secondary' }}>
            Job openings
          </Typography>
        </Stack>
      </Stack>
    </Card>
  );
}
