import PropTypes from 'prop-types';
// @mui
import { Typography, Stack, Grid} from '@mui/material';
// components
import Logo from '../../components/logo';
import Image from '../../components/image';
//
import { StyledRoot, StyledSectionBg, StyledSection, StyledContent } from './styles';

// ----------------------------------------------------------------------

LoginLayout.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node,
  illustration: PropTypes.string,
};

export default function LoginLayout({ children, illustration, title }) {
  return (
    <Grid
      container
      spacing={0}
      direction="column"
      alignItems="center"
      justifyContent="center"
      style={{ minHeight: '20vh' }}
    >

      <Grid item xs={3}>
        <Stack sx={{ width: 1 }}> {children} </Stack>
      </Grid>
    </Grid>
  );
}
