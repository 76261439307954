// routes
import { PATH_DASHBOARD } from '../../../routes/paths';
// components
import SvgColor from '../../../components/svg-color';

// ----------------------------------------------------------------------

const icon = (name) => <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />;

const ICONS = {
  user: icon('ic_user'),
  ecommerce: icon('ic_ecommerce'),
  analytics: icon('ic_analytics'),
  dashboard: icon('ic_dashboard'),
  tracker: icon('ic_dashboard'),
};

const navConfig = [
  // GENERAL
  // ----------------------------------------------------------------------
  {
    subheader: 'Tracking Companies',
    items: [
      { title: 'Features', path: PATH_DASHBOARD.one },
      { title: 'About', path: PATH_DASHBOARD.two },
      // { title: 'Pricing', path: PATH_DASHBOARD.three },
      // {
      //   title: 'Use Cases',
      //   path: PATH_DASHBOARD.user.four,
      //   // icon: ICONS.user,
      //   children: [
      //     { title: 'explore fundraising', path: PATH_DASHBOARD.user.four },
      //     { title: 'explore headcount', path: PATH_DASHBOARD.user.five },
      //     { title: 'explore crypto', path: PATH_DASHBOARD.user.trackerCrypto },
      //     // { title: 'example company', path: PATH_DASHBOARD.user.seven },
      //   ],
      // },
      {
        title: 'trackers',
        path: '/tracker/crypto',
        children: [
          { title: 'S&P 100', path: '/tracker/S&P%20100' },
          { title: 'crypto ', path: '/tracker/crypto' },
          { title: 'energy', path: '/tracker/energy' },
          { title: 'healthcare', path: '/tracker/healthcare' },
        ],
      },
    ],
  },
];

export default navConfig;
