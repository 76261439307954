import { Helmet } from 'react-helmet-async';
import React, { useState, useEffect } from 'react';
import { useParams, Link } from "react-router-dom";
// @mui
import { Grid, Container, Typography, Stack, Card, CardHeader, Box, MuiEvent, Icon, Skeleton } from '@mui/material';
import { DataGrid, GridToolbar, GridRenderCellParams, GridValueFormatterParams, GridCellParams } from '@mui/x-data-grid';
import { alpha } from '@mui/material/styles';
// components
import { useSettingsContext } from '../../components/settings';
import DataGridTracker from '../mui/data-grid/DataGridTracker'
import Footer from '../../layouts/Footer';
import { supabase } from '../../components/supabaseClient'
import { fNumber, fPercent, fOneDecimalNumber } from '../../utils/formatNumber';
import { fDate, } from '../../utils/formatTime';
import Iconify from '../../components/iconify';

// ----------------------------------------------------------------------

const headcountcolumns = [
  {
    field: 'timestamp',
    headerName: 'Last scan',
    minWidth: 100,
    maxWidth: 170,
    valueGetter: (params) => {
      if (params.row.linkedin_jobs && params.row.linkedin_jobs.length > 0) {
        const linkedinJobs = params.row.linkedin_jobs;
        // Use the reduce() method to iterate over the linkedinJobs array and keep track of the most recent job
        const mostRecentJob = linkedinJobs.reduce((mostRecent, current) => {
          // Compare the current job's timestamp to the mostRecent job's timestamp and return the most recent job
          return (current.timestamp > mostRecent.timestamp) ? current : mostRecent;
        });
        return mostRecentJob.timestamp;
      }
      return null;
    },
    renderCell: (params) => {
      // Log the value of the params.value parameter
      // console.log('params', params.value);
      return (
        <Typography variant="body2">
          {fDate(params.value * 1000)}
        </Typography>
      );
    },
    flex: 1,
  },
  {
    field: 'company',
    headerName: 'Company',
    minWidth: 120,
    // maxWidth: 200,
    valueGetter: (params) => { return params.row.company },
    renderCell: (params: GridRenderCellParams<String>) => (
      <Link to={`/company/${params.row.id}`}>{params.row.company}
      </Link>),
    flex: 1,
    align: 'left',
  },
  {
    field: 'logo',
    headerName: 'Logo',
    maxWidth: 120,
    valueGetter: (params) => { return params.row.company },
    renderCell: (params: GridRenderCellParams<String>) => (
      params.row.clearbit_logo != null ? (
        <Link to={`/company/${params.row.id}`}>
          <img
            src={params.row.clearbit_logo}
            alt="company logo"
            style={{
              'maxHeight': '30px',
              'maxWidth': '50px',
              'display': 'block',

            }}
            title={params.row.company}
          />
        </Link>) : (
        <Link to={`/company/${params.row.company.id}`} />)
    ),
    flex: 1,
    align: 'center',
  },
  {
    field: 'employees',
    minWidth: 90,
    // maxWidth: 120,
    headerName: 'Employees',
    flex: 1,
    align: 'left',
    sortable: true,
    valueGetter: (params) => {
      let result = 0;
      try {
        if (params.row.linkedin_jobs[0]) {
          if (params.row.company) {
            result = params.row.linkedin_jobs.at(-1).employees;
          }
        }
      } catch (error) {
        // If the employees value is undefined, set it to null
        result = null;
      }
      return result;
    },
    valueFormatter: (params: GridValueFormatterParams<number>) => {
      if (params.value == null) {
        return '';
      }
      return params.value.toLocaleString();
    },
  },
  // {
  //   field: 'dailyemployees',
  //   mindWidth: 90,
  //   headerName: 'dailyemployees',
  //   valueGetter: (params) => {
  //     let result = 0;
  //     try {
  //       if (params.row.daily_counts[0]) {
  //         if (params.row.company) {
  //           result = params.row.daily_counts.at(-1).employees;
  //         }
  //       }
  //     } catch (error) {
  //       // If the employees value is undefined, set it to null
  //       result = null;
  //     }
  //     console.log('dailyemp--', params.row);
  //     return result;
  //   },
  //   valueFormatter: (params: GridValueFormatterParams<number>) => {
  //     if (params.value == null) {
  //       return '';
  //     }
  //     return params.value.toLocaleString();
  //   },

  // },
  {
    field: 'employees_change',
    minWidth: 130,
    // maxWidth: 240,
    headerName: 'Δ Employees',
    sortable: 'true',
    align: 'left',
    flex: 1,
    valueGetter: (params) => {
      let result = 0;
      // below checks that there are at least 10 scans otherwise skips to null
      if (params.row.linkedin_jobs && params.row.linkedin_jobs.length > 5) {
        const linkedinEmployees = params.row.linkedin_jobs;
        result = (params.row.linkedin_jobs.at(-1).employees - params.row.linkedin_jobs.at(5).employees) / params.row.linkedin_jobs.at(5).employees * 100;
        // console.log ('paramsrow--', params.row.linkedin_jobs)
      } else {
        result = null;
      }
      return result
    },
    renderCell: (params) => {
      const result = params.value;
      // Check if result is positive or negative, and render the appropriate icon
      if (result === 0) {
        return (
          <>
            -
          </>
        );
      }
      if (result > 0) {
        return (
          <>
            {fOneDecimalNumber(result)}%
            <Iconify
              icon="eva:trending-up-fill"
              sx={{
                m: 1,
                p: 0.5,
                width: 20,
                height: 20,
                borderRadius: '50%',
                color: 'success.main',
                bgcolor: (theme) => alpha(theme.palette.success.main, 0.16),
              }} />
          </>
        );
      }
      if (result < 0) {
        return (
          <>
            {fOneDecimalNumber(result)}%
            <Iconify
              icon="eva:trending-down-fill"
              sx={{
                m: 1,
                p: 0.5,
                width: 20,
                height: 20,
                borderRadius: '50%',
                color: 'error.main',
                bgcolor: (theme) => alpha(theme.palette.error.main, 0.16),
              }} />
          </>
        );
      }
      // console.log(result);
      return (
        <Typography>-</Typography>
      );
    },
  },
  {
    field: 'job_openings',
    minWidth: 90,
    // maxWidth: 125,
    headerName: 'Job Openings',
    flex: 1,
    align: 'left',
    valueGetter: (params) => {
      let result = [];
      if (params.row.linkedin_jobs[0]) {
        if (params.row.company) {
          result = params.row.linkedin_jobs.at(-1).job_openings;
        }
      }
      return result;
    },
    valueFormatter: (params: GridValueFormatterParams<number>) => {
      if (params.value == null) {
        return '';
      }
      return params.value.toLocaleString();
    },
  },
  // -------------- was using past month values to QA the deltas ------------- //
  // {
  //   field: 'jobs_past_month',
  //   minWidth: 90,
  //   maxWidth: 125,
  //   headerName: 'Job last month',
  //   flex: 0.5,
  //   align: 'right',
  //   valueGetter: (params) => {
  //     let result = 0;
  //     if (params.row.linkedin_jobs[0]) {
  //       if (params.row.company) {
  //         result = params.row.linkedin_jobs.at(9).job_openings;
  //       }
  //     }
  //     return result;
  //   },
  //   valueFormatter: (params: GridValueFormatterParams<number>) => {
  //     if (params.value == null) {
  //       return '';
  //     }
  //     return params.value.toLocaleString();
  //   },
  // },
  {
    field: 'jobs_change',
    minWidth: 110,
    // maxWidth: 200,
    headerName: 'Δ Jobs',
    align: 'left',
    flex: 1,
    valueGetter: (params) => {
      let result = 0;
      // below checks that there are at least 10 scans otherwise skips to null
      if (params.row.linkedin_jobs && params.row.linkedin_jobs.length > 5) {
        result = (params.row.linkedin_jobs.at(-1).job_openings - params.row.linkedin_jobs.at(5).job_openings) / params.row.linkedin_jobs.at(5).job_openings * 100;
      }
      return result;
    },
    renderCell: (params) => {
      const result = params.value;
      // Check if result is positive or negative, and render the appropriate icon
      if (result === 0) {
        return (
          <>
            -
          </>
        );
      }
      if (result > 0) {
        return (
          <>
            {fOneDecimalNumber(result)}%
            <Iconify
              icon="eva:trending-up-fill"
              sx={{
                m: 1,
                p: 0.5,
                width: 20,
                height: 20,
                borderRadius: '50%',
                color: 'success.main',
                bgcolor: (theme) => alpha(theme.palette.success.main, 0.16),
              }} />
          </>
        );
      }
      if (result < 0) {
        return (
          <>
            {fOneDecimalNumber(result)}%
            <Iconify
              icon="eva:trending-down-fill"
              sx={{
                m: 1,
                p: 0.5,
                width: 20,
                height: 20,
                borderRadius: '50%',
                color: 'error.main',
                bgcolor: (theme) => alpha(theme.palette.error.main, 0.16),
              }} />
          </>
        );
      }
      // console.log(result);
      return (
        <Typography>-</Typography>
      );
    },
  },
]

// ---------------------- Below are columns for people datagrid -----------------------------------------------

const peoplecolumns = [
  {
    field: 'company',
    headerName: 'Company',
    minWidth: 200,
    valueGetter: (params) => {
      let result = null;
      try {
        if (params.value[0]) {
          if (params.value) {
            result = params.value;
          }
        }
      } catch (error) {
        // If the name value is undefined, set it to null
        result = null;
      }
      console.log('result--', params.value)
      console.log('co-id--', params.value)
      return result;
    },
    valueFormatter: (params: GridValueFormatterParams<number>) => {
      if (params.value == null) {
        return '';
      }
      return params.value.toLocaleString();
    },
  },
  {
    field: 'name',
    headerName: 'Name',
    minWidth: 150,
  },
  {
    field: 'role',
    headerName: 'Role',
    minWidth: 200,
  },
  {
    field: 'linkedin',
    headerName: 'Contact Linkedin',
    flex: 1,
    renderCell: (params: GridRenderCellParams<String>) => (
      <a href={params.value}
        target="_blank"
        rel="noreferrer"
        alt="logo"
      >{params.value}</a>
    ),
  },
]

const Tracker = () => {
  const [trackerData, setTrackerData] = useState([]);
  const { tagName } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  // console.log("trackerData--", trackerData)
  // console.log("tagName--", tagName)

  const [rows, setRows] = useState(trackerData);
  const peopleTrackerData = trackerData.flatMap((item) => item.people.map((person) => {
    return {
      ...person,
      company: item.company
    }
  }))

  useEffect(() => {

    try {
      getTrackerData(trackerData);
    } catch (error) {
      console.error('isLoading error: ', error.message);
    }
  }, [isLoading, tagName]);

  async function getTrackerData() {
    const { data, error } = await supabase
      .from('companies')
      .select(`
        id,
        company,
        tags_array,
        linkedin,
        glassdoor,
        clearbit_logo,
        clearbit_domain,
        tags_array,
        linkedin_jobs(
            id,
            employees,
            job_openings,
            timestamp
        ),
        people(
          id,
          name,
          company_id,
          role,
          linkedin
        )
        `)
      // daily_counts:id(
      //   id,
      //   employees
      // )
      // .contains('tags_array', ['finance'])
      .contains('tags_array', [tagName])
      .order('timestamp', { foreignTable: 'linkedin_jobs', ascending: true });

    setTrackerData(data)
    console.log('We have just made 1 API call');
    console.log('datacheck--', trackerData)
    setIsLoading(false);

  }

  return <Container >
    <Stack >
      <Card sx={{ mb: 2 }}>
        <CardHeader title={`${tagName} tracker`}
          sx={{ mb: 2, textTransform: 'capitalize' }}
          titleTypographyProps={{ typography: 'subtitle' }}
        />
        <Box sx={{ height: 800 }}>
          <div style={{ position: 'relative' }}>
            {isLoading && (
              <div style={{ position: 'absolute', top: 0, left: 0, right: 0, bottom: 0 }}>
                <Skeleton
                  variant="rectangle"
                  sx={{ m: 4, paddingTop: '65%', borderRadius: 3 }}
                />
              </div>
            )}
            {!isLoading && trackerData && trackerData.length > 0 && (
              // This is the headcount datagrid 
              <DataGrid
                loadingRows={isLoading}
                noRowsRenderer={() => { }}
                style={{ height: '800px' }}
                columns={headcountcolumns}
                rows={trackerData}
                getRowId={(row: any) => row.id}
                components={{ Toolbar: GridToolbar }}
                density="compact"
                initialState={{
                  sorting: {
                    sortModel: [{ field: 'timestamp', sort: 'desc' }],
                  },
                }}
                componentsProps={{
                  toolbar: {
                    showQuickFilter: true,
                    quickFilterProps: { debounceMs: 500 },
                  },
                }}
              />
            )}
          </div>
        </Box>
      </Card>
    </Stack>

    {/* This is the people datagrid */}
    <Stack >
      <Card sx={{ mb: 2 }}>
        <CardHeader title={`${tagName} contacts`}
          sx={{ mb: 2, textTransform: 'capitalize' }}
          titleTypographyProps={{ typography: 'subtitle' }}
        />
        <Box sx={{ height: 800 }}>
          <div style={{ position: 'relative' }}>
            {isLoading && (
              <div style={{ position: 'absolute', top: 0, left: 0, right: 0, bottom: 0 }}>
                <Skeleton
                  variant="rectangle"
                  sx={{ m: 4, paddingTop: '65%', borderRadius: 3 }}
                />
              </div>
            )}
            {!isLoading && trackerData && trackerData.length > 0 && (
              <DataGrid
                loadingRows={isLoading}
                noRowsRenderer={() => { }}
                style={{ height: '800px' }}
                columns={peoplecolumns}
                rows={peopleTrackerData}
                getRowId={(row: any) => row.id}
                components={{ Toolbar: GridToolbar }}
                density="compact"
                componentsProps={{
                  toolbar: {
                    showQuickFilter: true,
                    quickFilterProps: { debounceMs: 500 },
                  },
                }}
              />
            )}
          </div>
        </Box>
      </Card>
    </Stack>
  </Container>

}

export default Tracker