import { useState, useEffect } from 'react';
// @mui
import { Alert, Tooltip, Stack, Typography, Link, Box } from '@mui/material';
// hooks
import { useAuthContext } from '../../auth/useAuthContext';
// layouts
import LoginLayout from '../../layouts/login';
//
import AuthLoginForm from './AuthLoginForm';
import AuthWithSocial from './AuthWithSocial';
import { supabase } from '../../components/supabaseClient'
import Account from './Account'

// ----------------------------------------------------------------------

export default function Login() {
  const { method } = useAuthContext();
  const [session, setSession] = useState(null)

  useEffect(() => {
    supabase.auth.getSession().then(({ data: { session } }) => {
      setSession(session)
    })

    supabase.auth.onAuthStateChange((_event, session) => {
      setSession(session)
    })
  }, [])

  return (
    <LoginLayout>
      <Stack sx={{ mb: 1, position: 'relative' }}>
        <Typography variant="h4">Tracking Companies</Typography>

        <div 
          className="container" 
          style={{ 
            padding: '10px 0 10px 0'
            }}
            >
            {!session ? (
              <AuthLoginForm />
            ) : (
            <Account key={session.user.id} session={session} />
            )}


        </div>

        {/* <Stack direction="row" spacing={0.5}>
          <Typography variant="body2">New user?</Typography>

          <Link variant="subtitle2">Create an account</Link>
        </Stack>

        <Tooltip title={method} placement="left">
          <Box
            component="img"
            alt={method}
            src={`/assets/icons/auth/ic_${method}.png`}
            sx={{ width: 32, height: 32, position: 'absolute', right: 0 }}
          />
        </Tooltip> */}
      </Stack>

    </LoginLayout>
  );
}
