import PropTypes from 'prop-types';
import { useState } from 'react';
import Chart from 'react-apexcharts';
// @mui
import { Container, Stack, Card, CardHeader, Box } from '@mui/material';
// components
import { Helmet } from "react-helmet-async";
import { CustomSmallSelect } from '../../components/custom-input';
import AppAreaInstalled from '../@dashboard/general/app/AppAreaInstalled';
import { fDate } from '../../utils/formatTime';
import { fShortenNumber } from '../../utils/formatNumber';
// import { fNumber } from '../../utils/formatNumber';

// ----------------------------------------------------------------------

const CompanyRatingsChart = (props) => {

  // console.log("CompanyHeadcount--", props.chartData)

  const options = {
    xaxis: {
      categories: props.timestamp,
      type: 'datetime',
      labels: {
        datetimeFormatter: {
          year: 'yyyy',
          month: 'MMM \'yy',
          day: 'dd MMM',
          hour: 'HH:mm'
        },
        style: {
          colors: "#94A1A1"
        }
      },
      hideOverlappingLabels: true
    },
    yaxis: [
      {
        title: {
          text: 'Overall rating',
          style: {
            color: '#94A1A1',
          }
        },
        labels: {
          formatter: (value) => (value),
          style: {
            colors: ['#94A1A1'],
          }
        },
        forceNiceScale: true,
        min: Math.min(...props.chartOverallRatings),
      },
    ],
    stroke: {
      curve: "smooth"
    },
    chart: {
      animations: {
        enabled: false
      },
      toolbar: {
        show: true,
        tools: {
          zoom: false,
          zoomin: false,
          zoomout: false,
          pan: false,
          reset: false
        },
        export: {
          csv: {
            filename: props.companyName
          },
          png: {
            filename: props.companyName
          },
          svg: {
            filename: props.companyName
          }
        }
      }
    },
    legend: {
      labels: {
        colors: ["#94A1A1"]
      }
    },
    dataLabels: {
      style: {
        colors: ['#94A1A1']
      }

    }
  }

  return (
    <>
      <Card>
        <CardHeader title="Rating data" sx={{ mb: 2 }} />
        <Box sx={{ mt: 3, mx: 0 }} dir="ltr">
          <Chart
            type="line"
            height="364"
            series={[
              {
                name: 'Overall rating',
                data: props.chartOverallRatings
              },
            ]}
            options={options}
          />
        </Box>
      </Card>
    </>
  )
}


export default CompanyRatingsChart