import * as React from 'react';
import { useState, useEffect, useRef } from "react";
import { Stack, CardHeader, Container, Tab, Card, Tabs, Box, Grid, Typography, IconButton, Chip, Divider } from '@mui/material';
import ApexCharts from 'react-apexcharts';
import { Link, useParams } from "react-router-dom";
import { Helmet } from 'react-helmet-async';
import { DataGrid, GridToolbar, GridRenderCellParams, GridValueFormatterParams } from '@mui/x-data-grid';
import GenericDataGrid from '../mui/data-grid/GenericDataGrid';
// components
import { supabase } from '../../components/supabaseClient'
import { useSettingsContext } from '../../components/settings/SettingsContext';
import CustomBreadcrumbs from '../../components/custom-breadcrumbs/CustomBreadcrumbs';
import Iconify from '../../components/iconify';
import { PATH_DASHBOARD } from '../../routes/paths';
import { _userAbout, _userFeeds, _userFriends, _userGallery, _userFollowers } from '../../_mock/arrays';
import {
    Profile,
    ProfileCover,
    ProfileFriends,
    ProfileGallery,
    ProfileFollowers,
} from '../@dashboard/user/profile';
import EmployeesJobsHighlight from './EmployeesJobsHighlight'
import AppAreaInstalled from '../@dashboard/general/app/AppAreaInstalled'
import CompanyHeadcountChart from './CompanyHeadcountChart';
import CompanyRatingsChart from './CompanyRatingsChart';
import ProfileAbout from '../@dashboard/user/profile/home/ProfileAbout';
import ProfileSocialInfo from '../@dashboard/user/profile/home/ProfileSocialInfo';
import { fDateTime } from '../../utils/formatTime';
import { fNumber } from '../../utils/formatNumber';
import EmployeesJobsDelta from './EmployeesJobsDelta';
import DataGridCoHeadcount from '../mui/data-grid/DataGridCoHeadcount';
import DataGridCoRatings from '../mui/data-grid/DataGridCoRatings';
import DataGridCoPeople from '../mui/data-grid/DataGridCoPeople';


const Company = () => {
    const [companyData, setCompanyData] = useState([]);
    const { companyId } = useParams();
    const { themeStretch } = useSettingsContext();
    const [rows, setRows] = useState(companyData);
    const [currentTab, setCurrentTab] = useState('headcount');
    const [isLoading, setIsLoading] = useState(false);

    const companyDomain = companyData.website
        ? companyData.website // check if we have website first
        : `https://${companyData.clearbit_domain}`; // fallback to clearbit domain

    const [ratingsHidden, setRatingsHidden] = useState(true);
    const [peopleHidden, setPeopleHidden] = useState(true);

    const TABS = [
        {
            value: 'headcount',
            label: 'Headcount',
            component:
                <>
                    <Grid container spacing={3} sx={{ mb: 2 }}>
                        <Grid item xs={12} md={4} >
                            <EmployeesJobsHighlight
                                employees={companyData.company ? companyData.linkedin_jobs.at(-1).employees : []}
                                jobOpenings={companyData.company ? companyData.linkedin_jobs.at(-1).job_openings : []}
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={12} md={12} lg={12} sx={{ mb: 2 }} >
                        <CompanyHeadcountChart
                            // isLoading={isLoading}
                            companyName={companyData.company}
                            timestamp={
                                companyData.linkedin_jobs &&
                                    companyData.linkedin_jobs.length > 0 ?
                                    companyData.linkedin_jobs.map(x => (x.timestamp * 1000)) : []
                            }
                            chartEmployees={companyData.linkedin_jobs && companyData.linkedin_jobs.length > 0 ? companyData.linkedin_jobs.map(x => x.employees) : []}
                            chartJobOpenings={companyData.linkedin_jobs && companyData.linkedin_jobs.length > 0 ? companyData.linkedin_jobs.map(x => x.job_openings) : []}
                        />
                    </Grid>
                    <Grid item xs={12} md={12} lg={12} sx={{ mb: 2 }} >
                        <Stack >
                            <Card>
                                <CardHeader title="Headcount scans" />
                                <Box sx={{ height: 500 }}>
                                    <DataGridCoHeadcount
                                        scanData={
                                            companyData.linkedin_jobs && companyData.linkedin_jobs.length > 0 ?
                                                companyData.linkedin_jobs.map(x => ({
                                                    "timestamp": (x.timestamp),
                                                    // old way, also w/ sorting broken: "timestamp": new Date(x.timestamp * 1000).toLocaleDateString("en-US"), 
                                                    "id": x.id,
                                                    "employees": fNumber(x.employees),
                                                    "jobOpenings": fNumber(x.job_openings)
                                                })) : []
                                        }
                                    />
                                </Box>
                            </Card>
                        </Stack>
                    </Grid>
                    {/* <Grid item xs={6} md={4}> */}
                    {/* <Stack spacing={3}> */}
                    {/* <EmployeesJobsDelta
                            employeesDelta={companyData.company ? companyData.linkedin_jobs.at(-1).employees : []}
                            jobOpeningsDelta={companyData.company ? companyData.linkedin_jobs.at(-1).job_openings : []}
                        /> */}
                    {/* </Stack> */}
                    {/* </Grid> */}
                </>
        },
        {
            value: 'ratings',
            label: 'Ratings',
            component:
                <>
                    <Grid item xs={12} md={12} lg={12} >
                        <Grid sx={{ mb: 2 }}>
                            <CompanyRatingsChart
                                companyName={companyData.company}
                                timestamp={companyData.glassdoor_reviews && companyData.glassdoor_reviews.length > 0 ? companyData.glassdoor_reviews.map(x => (x.timestamp * 1000)) : []}
                                chartOverallRatings={companyData.glassdoor_reviews && companyData.glassdoor_reviews.length > 0 ? companyData.glassdoor_reviews.map(x => x.overall_rating) : []}
                            />
                        </Grid>
                        <Grid item xs={12} md={12} lg={12} sx={{ mb: 2 }} >
                            <Stack >
                                <Card>
                                    <CardHeader title="Rating scans" sx={{ mb: 2 }} />
                                    <Box sx={{ height: 500 }}>
                                        <DataGridCoRatings
                                            scanData={
                                                companyData.glassdoor_reviews && companyData.glassdoor_reviews.length > 0 ?
                                                    companyData.glassdoor_reviews.map(x => ({
                                                        "timestamp": (x.timestamp),
                                                        "id": x.id,
                                                        "overallRating": (x.overall_rating),
                                                    })) : []
                                            }
                                        />
                                    </Box>
                                </Card>
                            </Stack>
                        </Grid>
                    </Grid>
                </>
        },
        {
            value: 'people',
            label: 'People',
            component: (
                // console.log('people2--', companyData.people),
                <Box sx={{ height: 500 }}>
                    <DataGridCoPeople
                        scanData={
                            companyData.people && companyData.people.length > 0 ?
                                companyData.people.map(x => ({
                                    "name": (x.name),
                                    "id": x.id,
                                    "linkedin": (x.linkedin),
                                    "role": (x.role),
                                })) : []
                        }
                    />
                </Box>
            ),
        }
    ];

    useEffect(() => {

        try {
            GetCompanyData(companyData);
        } catch (error) {
            console.error('loading error');
        }
    }, [companyId]);

    async function GetCompanyData() {

        const { data, error } = await supabase
            .from('companies')
            .select(`
            id,
            company,
            tags_array,
            linkedin,
            glassdoor,
            clearbit_logo,
            clearbit_domain,
            website,
            linkedin_jobs(
                id,
                employees,
                job_openings,
                timestamp
            ),
            glassdoor_reviews(
                id,
                overall_rating,
                timestamp
              ),
            people(
                id,
                name,
                company_id,
                role,
                linkedin
              )
            `)
            .eq('id', companyId)
            .order('timestamp', { foreignTable: 'linkedin_jobs', ascending: true });

        if (JSON.stringify(data[0]) !== JSON.stringify(companyData)) {
            // console.log('companyData--', companyData[0]);
            setCompanyData(data[0]);
            setIsLoading(false);

        }
        if (data[0].glassdoor) {
            setRatingsHidden(false);
            // console.log ('glassdoor--', companyData.glassdoor);
        }
        console.log('people3--', companyData.people);
        if (data[0].people && data[0].people.length > 0) {
            setPeopleHidden(false);
            console.log('people4--', data[0].people.length);
        }
        // console.log ('ratingsHidden--', ratingsHidden);


        const peopleData = data[0];

        console.log('newData--', data[0].people);
        console.log('data--', data[0]);

    }

    return (
        <>
            <Helmet>
                <title> {`${companyData.company}`} </title>
            </Helmet>

            <Container maxWidth={themeStretch ? false : 'lg'}>
                {/* <CustomBreadcrumbs
                    //   heading="Company info"
                    links={[
                        { name: 'Company', href: PATH_DASHBOARD.root },
                        { name: companyData != null ? companyData.company : '' }
                    ]}
                /> */}

                <Container>
                    <Stack direction="row" divider={<Divider orientation="vertical" flexItem />} spacing={1} alignItems="center" justifyContent="left" sx={{ mb: 2 }}>
                        {
                            companyData != null && companyData.clearbit_logo != null ?
                                (companyData.clearbit_domain != null ? (
                                    <a href={companyDomain}
                                        target="_blank"
                                        rel="noreferrer"
                                        alt="logo"
                                    >
                                        <img src={companyData.clearbit_logo} height="50" style={{ height: '100%', maxWidth: '120px', maxHeight: '30px' }} alt="logo" />
                                    </a>
                                ) : (
                                    <img src={companyData.clearbit_logo} height="50" style={{ height: '100%', maxWidth: '120px' }} alt="logo" />
                                )
                                ) : ''
                        }
                        <h2 style={{ marginBottom: '3px' }}>{companyData != null ? companyData.company : ''}</h2>
                        <Stack direction="row" spacing={0} alignItems="center">
                            {
                                companyData != null && companyData.clearbit_domain != null ?
                                    (
                                        <IconButton href=
                                            {companyDomain}
                                            target="_blank"
                                            rel="noreferrer"
                                            alt="company logo"
                                        >
                                            <Iconify icon='charm:link' color='default' />
                                        </IconButton>) : ""
                            }
                            {
                                companyData != null && companyData.linkedin != null ?
                                    (
                                        <IconButton href=
                                            {companyData.linkedin}
                                            target="_blank"
                                            rel="noreferrer"
                                            alt="company linkedin"
                                        >
                                            <Iconify icon='eva:linkedin-fill' />
                                        </IconButton>) : ""
                            }
                            {
                                companyData != null && companyData.glassdoor != null ?
                                    (
                                        <IconButton href=
                                            {companyData.glassdoor}
                                            target="_blank"
                                            rel="noreferrer"
                                            alt="company glassdoor"
                                        >
                                            <Iconify icon='carbon:logo-glassdoor' color='default' />
                                        </IconButton>) : ""
                            }
                        </Stack>
                    </Stack>
                    <Stack direction="row" spacing={1} alignItems="left" sx={{ mb: 2 }}>
                        {
                            companyData.tags_array && companyData.tags_array.length > 0 ? companyData.tags_array.map(x =>
                                <React.Fragment key={x}>
                                    <a href={`/tracker/${x}`} style={{ textDecoration: 'none' }}>
                                        <Chip
                                            clickable
                                            color="success"
                                            variant="outlined"
                                            size="small"
                                            label={x}
                                        />
                                    </a>
                                </React.Fragment>) : ''
                        }

                    </Stack>
                    <Card
                        sx={{
                            mb: 2,
                            height: 50,
                            position: 'relative',
                        }}
                    >
                        <Tabs
                            value={currentTab}
                            onChange={(event, newValue) => setCurrentTab(newValue)}
                            sx={{
                                width: 1,
                                bottom: 0,
                                left: 0,
                                zIndex: 9,
                                // position: 'absolute',
                                bgcolor: 'background.paper',
                                '& .MuiTabs-flexContainer': {
                                    padding: '0px',
                                    pr: { md: 3 },
                                    justifyContent: {
                                        sm: 'center',
                                        md: 'flex-start',
                                    },
                                },
                            }}
                        >
                            {TABS.map((tab) => (
                                (tab.value !== "ratings" || !ratingsHidden) && (tab.value !== "people" || !peopleHidden) && (
                                    <Tab
                                        key={tab.value}
                                        value={tab.value}
                                        icon={tab.icon}
                                        label={tab.label}
                                    />
                                )
                            ))}
                        </Tabs>
                    </Card>
                    {TABS.map((tab) => tab.value === currentTab && <Box key={tab.value}> {tab.component} </Box>)}
                </Container>
            </Container>
        </>
    )
}

export default Company